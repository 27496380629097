import React from 'react';

const ArrowDownButton = props => (
  <button
    onClick={props.handleClick}
    className={`btn-explore ${props.shouldReveal ? 'btn-explore--show' : ''} ${
      props.hasVideo ? 'explore--has-video' : ''
    }`}
  >
    <span className={`f-label`}>
      <span>{props.text}</span>
    </span>{' '}
    <span className={`icon-arrow  `}>
      <svg>
        <circle cx="50%" cy="50%" r="49%" />
      </svg>
    </span>
  </button>
);

export default ArrowDownButton;
