import React, { PureComponent } from 'react';
import * as style from './intro.module.scss';
import Logo from './logo';
import Outrider from './outrider';

class Intro extends PureComponent {
  constructor() {
    super();
    this.state = {
      logoComplete: false,
      introComplete: false,
      oComplete: false,
      start: false,
    };
  }
  componentDidMount() {
    // on complte of O loop
    setTimeout(() => {
      this.setState({ start: true });
    }, window.innerWidth < 980 ? 1200 : 10);

    setTimeout(this.handleOComplete, window.innerWidth < 980 ? 2500 : 1310);
  }
  handleOComplete = () => {
    // triggers slide in of full name
    this.setState({ oComplete: true });
    setTimeout(this.handleLogoComplete, 3000);
  };
  handleHideComplete = () => {
    // removed element
    this.setState({ introComplete: true });
    this.props.onIntroComplete();
  };
  handleLogoComplete = () => {
    // triggers fade out
    this.setState({ logoComplete: true });
    this.props.startPage();
    setTimeout(this.handleHideComplete, 2000);
  };

  render() {
    return (
      <div>
        {this.state.introComplete ? null : (
          <div
            className={`page-intro ${
              this.state.logoComplete ? style.introHide : style.intro
            }`}
          >
            <div
              className={
                this.state.oComplete
                  ? style.logoContainerFull
                  : style.logoContainer
              }
            >
              {this.state.start && (
                <div className={style.logoSlide}>
                  <Logo animated />
                  <Outrider reveal={this.state.oComplete} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Intro;
