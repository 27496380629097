import React from 'react';
import * as style from './outrider.module.scss';

const Outrider = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 507.6 60.6"
      className={props.reveal ? style.outrider : style.outriderHidden}
      width="375px"
      height="45px"
      style={{ opacity: 0 }}
    >
      <title>outrider</title>
      <path
        style={{ display: 'none' }}
        d="M46.6,38.4A15.4,15.4,0,1,1,31.2,53.8,15.41,15.41,0,0,1,46.6,38.4m0-11.2A26.65,26.65,0,1,0,73.4,53.8,26.59,26.59,0,0,0,46.6,27.2"
        transform="translate(-12.6 -20.1)"
      />
      <path
        style={{ display: 'none' }}
        d="M24.4,26a5.9,5.9,0,1,1-5.9-5.9A5.91,5.91,0,0,1,24.4,26"
        transform="translate(-12.6 -20.1)"
      />
      <g className={style.letter}>
        <path
          d="M120.2,80.7c-13.6,0-21.9-7.6-21.9-22.4V29.4h11.1V58c0,8.2,4.1,12.5,10.9,12.5s10.9-4.1,10.9-12.1v-29h11.1V57.9C142.3,73.1,133.8,80.7,120.2,80.7Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
      <g className={style.letter}>
        <path
          d="M192.3,39.6V79.9H181.2V39.6H165.8V29.4h41.8V39.6Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
      <g className={style.letter}>
        <path
          d="M262.7,79.9,251.9,63.7h-8.7V79.9H232V29.4h23.1c11.9,0,19,6.3,19,16.7v.1c0,8.2-4.4,13.3-10.8,15.7l12.3,18Zm.2-33.3c0-4.8-3.3-7.2-8.7-7.2h-11V53.9h11.3c5.4,0,8.5-2.9,8.5-7.1v-.2Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
      <g className={style.letter}>
        <path
          d="M300.5,79.9V29.4h11.1V79.9Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
      <g className={style.letter}>
        <path
          d="M359.8,79.9H340.1V29.4h19.7c15.9,0,26.8,10.9,26.8,25.1v.1C386.6,68.8,375.6,79.9,359.8,79.9ZM375,54.6c0-8.9-6.1-15.2-15.2-15.2h-8.6V69.8h8.6c9.1,0,15.2-6.1,15.2-15.1Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
      <g className={style.letter}>
        <path
          d="M412.4,79.9V29.4h38.1v9.9H423.4V49.5h23.8v9.9H423.4V70h27.4v9.9Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
      <g className={style.letter}>
        <path
          d="M507.2,79.9,496.4,63.7h-8.7V79.9H476.6V29.4h23.1c11.9,0,19,6.3,19,16.7v.1c0,8.2-4.4,13.3-10.8,15.7l12.3,18Zm.2-33.3c0-4.8-3.3-7.2-8.7-7.2h-11V53.9H499c5.4,0,8.5-2.9,8.5-7.1v-.2Z"
          transform="translate(-12.6 -20.1)"
        />
      </g>
    </svg>
  );
};

export default Outrider;
