import React, { Component } from 'react';
import * as style from './heroMailChimpForm.module.scss';
import * as buttonStyle from '../ui/button.module.scss';
import $ from 'jquery';
import FormData from 'form-data';

import translations from '../../utils/translations';

class HeroMailChimpForm extends Component {
	constructor() {
		super();
		this.onSubmit = this.onSubmit.bind(this);
	};

	onSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const data = {
			'EMAIL' : 	formData.get('EMAIL'),
			'b_f638b14bd99d4a67f72a673b2_b758bb0b52': ''
		};
		$.ajax({
			url: 'https://outrider.us12.list-manage.com/subscribe/post-json?u=f638b14bd99d4a67f72a673b2&amp;id=b758bb0b52&c=?',
			dataType: 'jsonp',
			data: data,
			cache: false,
			success: function(data) {
				$('#mce-error-response').removeAttr('style').css('display', 'none');
				$('#mce-success-response').removeAttr('style').css('display', 'none');
				if(data.result && data.result === 'error') {
					$('#mce-error-response').html(data.msg);
					$('#mce-error-response').css('display', 'block');
				} else if(data.result && data.result === 'success') {
					$('#mailchimp-sign-up-form').removeAttr('style').css('display', 'none');
					$('#mailchimp-thank-you-message').removeAttr('style');
				} else {
					$('#mce-error-response').html('An unknown error has occurred');
					$('#mce-error-response').css('display', 'block');
				}
			}.bind(this),
			error: function(xhr, status, err) {
				$('#mce-error-response').html(err.toString());
				$('#mce-error-response').css('display', 'block');
			}.bind(this)
		});
	};

	render() {
		const {
			menu_social,
			showLogo,
		} = this.props;
		const blogURL = (this.props.blogData) ? this.props.blogData.edges[0].node.path : '';

		return (
			<div id="mc_embed_signup" className={`${style.form} text-block page-block normal-text-block`}>
				<div id="mailchimp-sign-up-form">
					<form onSubmit={this.onSubmit} id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
						{/*
						{showLogo &&
							<a href={blogURL}><div className={style.logo} /></a>
						}
						{!showLogo &&
							<div className={style.smallHeader}>Sign up for Outrider emails</div>
						}
						*/}
						{/*
						<div className={style.largeText}>Get the Latest Articles from Outrider</div>
						*/}
						
						<div className={`${style.inputGroup} mc-field-group`}>
							
							{/*
								placeholder="Your email address"
								Email Address
							*/}
							<label htmlFor="mce-EMAIL" style={{display: 'none'}}>{translations.t('Email Address')}</label>
							<input
								type="email"
								name="EMAIL"
								className={`${style.emailInput} required email`}
								id="mce-EMAIL"
								placeholder={translations.t('Your email address')}
							/>
							
							<div className={style.spacer}>&nbsp;</div>
							
							{/*
								Sign me up!
							*/}
							<button
								type="submit"
								name="subscribe"
								id="mc-embedded-subscribe"
								className={`${buttonStyle.button} ${buttonStyle.orangeButton} ${style.submitButton}`}>
									<span className={buttonStyle.mainText}>{translations.t('Sign me up!')}</span>
							</button>
							
							<div id="mce-responses" className="clear">
								<div className={`${style.errorResponse} response`} id="mce-error-response" style={{display: 'none'}}></div>
								<div className="response" id="mce-success-response" style={{display: 'none'}}></div>
							</div>
							
						</div>
					</form>
					
				</div>
				
				<div id="mailchimp-thank-you-message" style={{display: 'none'}}>
					<div className={style.smallGreyText}>Thank you</div>
					<div>{/* MISSING_CLASS_NAME: className={style.largeWhiteText} */}Almost there!</div>
					<div className={style.textBlock} style={{width: '100%'}}>
						Thanks for your interest in our newsletter! An email is on its way. Please 
						make sure to activate your subscription, by clicking on the confirmation link.
					</div>
				</div>
				
				{/*
				{menu_social &&
					<ul className={style.navSocial}>
						{menu_social.map((item, i) => (
							<li key={`socialitem${i}`} className={style.navItem}>
								<a
									href={item.url}
									className={`icon-social icon-circle-white icon-medium ${item.class}`}
									target={item.target}
									rel="noopener"
								>
									<span>{item.title}</span>
								</a>
							</li>
						))}
					</ul>
				}
				*/}
			</div>
		);
	};
};

export default HeroMailChimpForm;
