import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loadImage from '../../utils/loadImage';
import * as style from './lazyBackground.module.css';

class LazyBackground extends Component {
  constructor() {
    super();
    this.state = {
      startedLoad: false,
      loaded: false,
      reveal: false,
    };
  }
  componentDidMount() {
    if (!this.props.hasRevealer || this.props.revealed) {
      this.load();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!this.state.startedLoad && !this.props.revealed && nextProps.revealed) {
      this.load();
    }
  }
  load() {
    this.setState({ startedLoad: true });
    loadImage(this.props.src).then(img => {
      this.setState({ loaded: true }, () => {
        setTimeout(this.reveal, 1);
      });
    });
  }
  reveal = () => {
    this.setState({ reveal: true });
  };

  render() {
    const display = this.state.reveal && !this.props.hidden;
    const loadStyle = {
      backgroundImage: display ? `url(${this.props.src})` : '',
      opacity: display ? this.props.opacity : 0,
      transform: display ? `scale(1.15)` : `scale(1)`,
    };

    return (
      <div>
        <div className={`${style.lazyBackground}`} style={loadStyle} />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<div class="${
              style.lazyBackground
            }" style="backgroundImage: url(${
              this.props.src
            }); opacity: 0.35; transform: scale(1);"></div>`,
          }}
        />
      </div>
    );
  }
}

LazyBackground.defaultProps = {
  opacity: 0.35,
  hidden: false,
};

LazyBackground.propTypes = {
  src: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  hidden: PropTypes.bool,
};

export default LazyBackground;
