import React, { Component } from 'react';
import Reveal from '../ui/reveal';
import LazyImage from '../ui/lazyImage';
import CircleButton from '../ui/circleButton';
import * as style from './relatedContentCell.module.scss';
import * as buttonStyle from '../ui/circleButton.module.scss';

class RelatedContentCell extends Component {
	constructor() {
		super();
		this.state = {
			width: 0, 
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}
	
	render() {
		let imageSrc = (this.props.image.sizes.near_square_md) ? this.props.image.sizes.near_square_md : this.props.image.sizes.original;
		let width = 670;
		let height = 596;
		const order = { order: this.props.order };
		const circleButtonType = (this.props.buttonType) ? this.props.buttonType : 'open';
		
		if(this.state.width <= 768) {
			if(this.props.size === 'Medium') {
				imageSrc = (this.props.image.sizes.landscape_sm) ? this.props.image.sizes.landscape_sm : imageSrc;
				width = 375;
				height = 250;
			}
		} else {
			if(this.props.size === 'Medium') {
				imageSrc = (this.props.image.sizes.wide_lg) ? this.props.image.sizes.wide_lg : imageSrc;
				width = 1370;
				height = 596;
			} else if(this.props.size === 'Large') {
				imageSrc = (this.props.image.sizes.wide_lg) ? this.props.image.sizes.wide_lg : imageSrc;
				width = 2000;
				if(this.state.width <= 1200) {
					height = 1000;
				} else {
					height = 833;
				}
			}
		}
		const path = (this.props.cta_link) ? this.props.cta_link.url : this.props.path;
		const target = (this.props.cta_link && this.props.cta_link.target === 'external') ? 'target="_blank"' : '';
		
		return (
			<div className={`${style.wrapper}
					${this.props.size === 'Medium' ? 'medium-cell' : ''}
					${this.props.size === 'Large' ? 'large-cell' : ''}
					${this.props.size === 'Small' ? 'small-cell' : ''}
					${this.props.order === '2' ? 'curriculum-spacer' : ''}`}
			
				style={order}
			>
				
				<div className={`${style.container} ${buttonStyle.buttonHoverContainer}`}>
					<a href={path} target={target}>
						<Reveal>
							<LazyImage
								src={imageSrc}
								alt={this.props.image.alt_text}
								hasRevealer={false}
								revealed={true}
								width={width}
								height={height}
								fitSize={true}
								opacity={.5}
							/>
						</Reveal>
					
						<div className={style.textContainer}>
							<div className={style.type}>{this.props.content_type_label}</div>
							<div className={style.title}>{this.props.title}</div>
						</div>
						<div className={style.circleButton}>
							<CircleButton type={circleButtonType} />
						</div>
					</a>
				</div>
			</div>
		);
	}
};

export default RelatedContentCell;
