import React, { Component } from 'react';
import LazyImage from '../ui/lazyImage';
import * as style from '../magazine/featuredArticle.module.scss';
import Button from '../ui/button';
import translations from '../../utils/translations';

class FeaturedArticle extends Component {
	render() {
		const {
			article,
			t,
			wideImage
		} = this.props;
		const imageSrc = (wideImage) ? article.image.sizes.standard_md : article.image.sizes.near_square_md;
		const authors = (article.authors) ? article.authors.map((author) => author.name).join(' / ') : null;
		const height=  (wideImage) ? 900 : 586;
		const width =  (wideImage) ? 1200 : 670;
		const path = (article.cta_link) ? article.cta_link.url : article.path;
		const linkTarget = (article.cta_link && article.cta_link.target === 'external') ? '_blank' : '';

		return (
			<div className={style.featuredArticle}>
				<a href={path} target={linkTarget}>
					<LazyImage
						src={imageSrc}
						alt={article.image.alt_text	}
						hasRevealer={false}
						revealed={true}
						width={width}
						height={height}
						fitSize={true}
					/>
				</a>
				<div>{/* MISSING_CLASS_NAME: className={style.articleDetails} */}
					<div className={style.category}>
					{article.archive && (
						<a href={article.archive.path}>{article.archive.title}</a>
					)}
					</div>
					<div className={style.title}><a href={article.path}>{article.title}</a></div>
					{!(authors === null || authors === '') &&
						<div className={style.author}>{translations.t('by')} {authors}</div>
					}
					<div
						dangerouslySetInnerHTML={{
							__html: article.short_description,
						}}
						className={style.description}
					/>
					{article.cta_link &&
						<Button
							color="orange"
							to={article.cta_link.url}
							text={article.cta_link.title}
							target={article.cta_link.target}
						/>
					}
					{!article.cta_link &&
						<Button
							color="orange"
							to={article.path}
							text={translations.t('read story')}
						/>
					}
				</div>
			</div>
		)
	}
}

export default FeaturedArticle;
