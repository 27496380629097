import React, { Component } from 'react';
import basicMapDataToComponents from '../../templates/basicMapDataToComponents';
import RelatedContentCell from '../corecurriculum/relatedContentCell';
import FeaturedArticle from '../magazine/featuredArticle';

class HomeContent extends Component {
  constructor() {
    super();
    this.state = {
      width: 0, 
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  getArticleStartIndex() {
   return ( this.state.width > 1200 ) ? 2 : 0;
  }
  render() {
    var paragraphs = [];
    var bottomCallouts = [];
    this.props.paragraphs.forEach((paragraph) => {
        if(paragraph.type === 'bottom_callouts') {
            bottomCallouts.push(paragraph);
        } else {
            paragraphs.push(paragraph);
        }
    });
    return (
      <div id="content" className={`content-homepage cf`}>
        {paragraphs.length > 0 &&
          basicMapDataToComponents(paragraphs , { reveal: true })
        }
        {typeof this.props.articles[0] !== 'undefined' &&
	        <div className="articleContainer">
	          <div className="articleWrapper">
	            <FeaturedArticle article={this.props.articles[0]} wideImage={true} />
	            {this.state.width > 1200 && typeof this.props.articles[1] !== 'undefined' &&
	              <div className="smallFeatureWrapper">
	                <RelatedContentCell key="articleRight1" {...this.props.articles[1]} />
	                {typeof this.props.articles[2] !== 'undefined' &&
		                <RelatedContentCell key="articleRight2" {...this.props.articles[2]} />
					}
	              </div>
	            }
	            <div className="articleList">
	              {this.props.articles.map((article, x) => {
	                if(x > this.getArticleStartIndex()) {
	                  return(<RelatedContentCell key={`article${x}`} {...article}/>);
	                }
	              })}
	            </div>
	          </div>
	        </div>
        }
        {bottomCallouts.length > 0 &&
          basicMapDataToComponents(bottomCallouts , { reveal: true })
        }
      </div>
    );
  }
}

export default HomeContent;
