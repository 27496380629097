// extracted by mini-css-extract-plugin
export var form = "heroMailChimpForm-module--form--4jnfj";
export var logo = "heroMailChimpForm-module--logo--crxKt";
export var smallGreyText = "heroMailChimpForm-module--smallGreyText--8wSJy";
export var smallHeader = "heroMailChimpForm-module--smallHeader--kbLhr";
export var largeText = "heroMailChimpForm-module--largeText--MPqtC";
export var textBlock = "heroMailChimpForm-module--textBlock--DISu+";
export var inputGroup = "heroMailChimpForm-module--inputGroup--fdWsl";
export var spacer = "heroMailChimpForm-module--spacer--oEaQV";
export var navSocial = "heroMailChimpForm-module--navSocial--SJtJb";
export var emailInput = "heroMailChimpForm-module--emailInput--eWmXU";
export var submitButton = "heroMailChimpForm-module--submitButton--EM+eS";
export var errorResponse = "heroMailChimpForm-module--errorResponse--kSUUz";
export var footer = "heroMailChimpForm-module--footer--+V7ns";
export var slideUp = "heroMailChimpForm-module--slideUp--LKQx3";
export var slideUpLarge = "heroMailChimpForm-module--slideUpLarge--iVjPC";