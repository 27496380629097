import React from 'react';
import LazyBackground from './lazyBackground';
import VideoBackground from '../basic/videoBackground';
import * as style from './contentBackground.module.scss';

const ContentBackground = props => (
  <div
    className={
      props.playEmbed ? style.contentBackgroundPlaying : style.contentBackground
    }
  >
    {props.videoSrc ? (
      <div className={style.contentVideo}>
        <VideoBackground
          src={props.videoSrc}
          videoStyle="cover"
          opacity={props.videoOpacity}
          slowFade={props.videoSlowFade}
          poster={props.poster ? props.poster : ''}
          paused={props.paused}
        />
      </div>
    ) : (
      <div className={style.contentImage}>
        <LazyBackground
          src={props.src}
          opacity={props.opacity}
          hidden={props.hidden}
        />
      </div>
    )}
  </div>
);

export default ContentBackground;
